/*----------------------------------------------------------------------------*\
    NB COOKIE POPUP
    .nb--cookiepopup
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    @import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,500i,700,700i');

    $font-base: 'Roboto', sans-serif;

    $color-text: #414141;
    $color-text-link: #6b8bb4;
    $color-btn: #3d723d;  
    $color-link: #979797;    

/*  Component
\*----------------------------------------------------------------------------*/

    .nb--cookiepopup{
        display: none;
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: 99999;

        .img--full,
        iframe{
            display: block;
        }

        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 1;
        }
    }

    .nb--cookiepopup[data-nbcookiestate="active"]{
        display: block;
    }

    .nb--cookiepopup__box{
        display: block;
        max-width: 670px;
        padding: 36px;
        width: 95%;
        max-height: 80%;
        background-color: #FFF;        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        overflow: auto;
        box-sizing: border-box;

        font-family: $font-base;
        font-size: 14px !important;
        line-height: 24px !important;

        a{
            white-space: nowrap;
        }

        p{
            margin-bottom: 24px;

            a{
                color: $color-text-link;
                transition: color .3s cubic-bezier(0.4, 0.0, 0.2, 1.0);

                &:hover{
                    color: lighten($color-text-link, 10%);
                }
            }
        }
    }

    .nb--cookiepopup__title{
        font-size: 24px !important;
        font-weight: 600 !important;
        margin-bottom: 12px !important;
        color: $color-text !important;
        text-transform: none !important;
    }

    .nb--cookiepopup__btn{
        display: inline-block;
        background-color: $color-btn;
        color: #FFF;
        padding: 12px 24px;
        font-size: 14px !important;
        text-decoration: none;
        transition: background-color .3s cubic-bezier(0.4, 0.0, 0.2, 1.0);

        &:hover{
            background-color: lighten($color-btn, 10%);
        }
    }

    .nb--cookiepopup__link{
        color: $color-link !important;
        font-size: 14px !important;
        text-decoration: none;
        transition: color .3s cubic-bezier(0.4, 0.0, 0.2, 1.0);

        &:hover{
            color: $color-text;
        }
    }

    .nb--cookiepopup__form{
        margin-bottom: 24px;
    }

    .nb--cookiepopup__field{
        margin-bottom: 12px;
    }

    .nb--cookiepopup__required{
        display: none;
        font-size: 13px !important;
        line-height: 21px !important;
        color: #8a1f1f !important;
    }

    .nb--cookiepopup__field--radio,
    .nb--cookiepopup__field--checkbox{
        font-size: 0;

        input{
            display: inline-block;
            width: auto !important;
            height: auto !important;
            vertical-align: top;
            margin: 8px 10px 0 0 !important;
            font-size: 14px !important;
        }
        label{
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 50px);
            font-size: 14px !important;
            line-height: 30px !important;
            font-weight: 400 !important;

            strong{
                font-weight: 700 !important;
            }

            .nb--cookiepopup__label{
                margin-left: 6px;
                padding: 6px !important;
                color: #FFF !important;
                background-color: #4175b7 !important;
                font-size: 12px !important;
            }
        }
    }

    .nb--cookiepopup__field--radio{
        input[type=radio]{
            -webkit-appearance: radio;
        }
        
    }

    .nb--cookiepopup__field--checkbox{
        input[type=checkbox]{
            -webkit-appearance: checkbox;
        }        
    }

    @media screen and (max-width: 480px) {
        .nb--cookiepopup__box{
            padding: 24px;

            h2{
                margin: 0 0 10px;
            }
        }
    }